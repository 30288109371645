import React from 'react'
import ProjectPreview from './project-preview'

function ProjectPreviewGrid (props) {
  return (
    <div className='mt-20 mx-auto'>
      <ul className='w-full'>
        {props.nodes &&
          props.nodes.map(node => (
            <li key={node.id} className='w-full'>
              <ProjectPreview {...node} />
            </li>
          ))}
      </ul>
      {/* {props.browseMoreHref && (
        <div className={styles.browseMoreNav}>
          <Link to={props.browseMoreHref}>Browse more</Link>
        </div>
      )} */}
    </div>
  )
}

ProjectPreviewGrid.defaultProps = {
  title: '',
  nodes: [],
  browseMoreHref: ''
}

export default ProjectPreviewGrid
