// import {Link} from 'gatsby'
import React from 'react'
// import BlockText from './block-text'
import Img from 'gatsby-image'

function ProjectPreview (props) {
  return (
    // <Link to={`/project/${props.slug.current}`}>
    <div className='lg:px-4' id={props.slug.current}>
      <div className='mx-auto'>
        {props.mainImage && props.mainImage.asset && (
          <figure className='feed mx-auto mb-7 lg:mb-14'>
            <Img
              fluid={props.mainImage.asset.fluid}
              alt={props.mainImage.alt}
              imgStyle={{objectFit: 'contain'}}
              fadeIn='true'
              className='border-gray-300 lg:border-black border-b lg:max-h-6/10 border-t lg:py-4'
            />
            <figcaption className='pt-2 px-4 text-xs lg:text-center'>
              <span className='pr-4'>{props.mainImage.year}</span>
              {props.mainImage.caption}
              {props.mainImage.credit && (
                <span className='text-gray-600'> — Credits: {props.mainImage.credit}</span>
              )}
            </figcaption>
          </figure>
        )}
      </div>
      {/* <h3 className={cn(responsiveTitle3, styles.title)}>{props.title}</h3> */}
      {/* {props._rawExcerpt && (
        <div className='mb-10'>
          <BlockText blocks={props._rawExcerpt} />
        </div>
      )} */}
    </div>
    // </Link>
  )
}

export default ProjectPreview
